import React, { VFC } from 'react';
import { LinkIconButton } from './LinkIconButton';
import { TCanvas } from './three/TCanvas';

export const App: VFC = () => {
	return (
		<div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
			<TCanvas />
			<LinkIconButton imagePath="/assets/icons/web.png" linkPath="mailto:elin@eiart.net" />
		</div>
	)
}